import { useState, useEffect } from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css"; // Import the styles

import style from "./CarImageLightbox.module.css";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Animation imports
import AOS from "aos";
import "aos/dist/aos.css";

const CarImageLightbox = ({data}) => {
  console.log('images',data);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 1500 }); // Initialize AOS with options (adjust duration as needed)
  }, []);

  const images = [
    "https://images.unsplash.com/flagged/photo-1553505192-acca7d4509be?q=80&w=2090&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1555215695-3004980ad54e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Ym13fGVufDB8fDB8fHww",
    "https://images.unsplash.com/photo-1617531653332-bd46c24f2068?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGJtd3xlbnwwfHwwfHx8MA%3D%3D",
    "https://images.unsplash.com/photo-1614026480209-cd9934144671?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGJtd3xlbnwwfHwwfHx8MA%3D%3D",
  ];

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  return (
    <MKBox component="section" pt={5} pb={8} width="100%" data-aos="fade-left">
      <Container>
        <MKTypography variant="h2" pb={3}>
          Image Gallery
        </MKTypography>
        <Grid className={style.image_container} item>
          {data.data.map((image, index) => (
            <Grid
              item
              xs={6}
              sm={1}
              md={3}
              lg={3}
              key={index}
              pr={2}
              pb={2}
              className="pr-lg-2 pb-lg-2"
            >
              <img
                src={`http://admin.kondodycarworld.com/${image.attributes.url}`}
                width="100%"
                alt={`Image ${index}`}
                onClick={() => openLightbox(index)}
                className={style.image_grid}
              />
            </Grid>
          ))}
        </Grid>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}
      </Container>
    </MKBox>
  );
};

export default CarImageLightbox;
